import { addLocaleData } from 'react-intl';

import 'moment/locale/zh-cn';
import antdLocale from 'antd/lib/locale-provider/zh_CN';
import intlLocale from 'react-intl/locale-data/zh';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
